<template>
    <div>
      <div class="fixed inset-0 bg-red-200" style="opacity: 0.5; z-index: 99;" aria-hidden="true"></div>
      <!--   delete modal   -->
      <div class="inset-0 fixed flex justify-center items-center" style="z-index: 99;">
        <div
          class=" flex flex-col w-16/12 sm:w-5/6 lg:w-1/2 max-w-md mx-auto rounded-lg border border-gray-300 shadow-xl">
          <div
            class=" flex flex-row justify-between items-center px-3 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
            <p class="font-semibold text-sm text-gray-800">Refraicher une série</p>
            <svg @click="closeResetSession" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </div>
          <div class="flex flex-col px-6 py-5 bg-gray-50">
            <div class=" flex flex-col sm:flex-row items-center text-center text-sm p-3 sm:space-x-5">
              <div class="w-full">
                <span v-if="type == 'all'">Voulez-vous vraiment réinitialiser cette série ?</span>
                <span v-if="type == 'incorrect'">Voulez-vous vraiment réinitialiser les réponses incorrectes de cette série ?</span>
              </div>
            </div>
          </div>
          <div
            class=" flex flex-row items-center justify-between px-6 py-3 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg ">
            <button @click="closeResetSession" class="font-semibold text-gray-600 text-sm">
              Annuler
            </button>
            <button @click="confirm(selectedSessionData.id)"
              class=" text-sm px-4 text-red-400 font-semibold bg-white rounded" :disabled="isLoading">
              Confirmer
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  //import { createWidgetMixin } from 'vue-instantsearch/vue3/es';
  import { ElMessage } from 'element-plus';
  import SessionRequests from '../../../../../services/requests/session';
  
  /*const connectRefresh = (renderFn, unmountFn) => (widgetParams = {}) => ({
    init() {
      renderFn({ refresh() {} }, true);
    },
  
    render({ instantSearchInstance }) {
      const refresh = instantSearchInstance.refresh.bind(instantSearchInstance);
  
      renderFn({ refresh }, false);
    },
  
    dispose() {
      unmountFn();
    },
  });*/
  
  export default {
    name: 'resetResidanatSessionModal',
    //mixins: [createWidgetMixin({ connector: connectRefresh })],
    props: {
      selectedSessionData: Object,
      closeResetSession: Function,
      type: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        isLoading: false
      }
    },
    methods: {
      refresh() {
        let vm = this;
        setTimeout(function () {
          vm.state.refresh();
        }, 4000)
      },
  
      confirm(sessionId) {
        this.isLoading = true;
        if(this.type == 'all') {
          this.resetSession(sessionId);
        } else if(this.type == 'incorrect') {
          this.resetIncorrectResponses(sessionId);
        }
      },
  
      async resetSession(sessionId) {
        try {
          const response = await SessionRequests.resetAnnalesResidanatSession(sessionId);
          if (response.data.message) {
            this.$emit('done');
            this.closeResetSession();
            this.successMessage(response.data.message);
          }
        } catch (error) {
          this.errorm();
        } finally {
          this.isLoading = false;
        }
      },
  
      async resetIncorrectResponses(sessionId) {
        try {
          const response = await SessionRequests.resetAnnalesResidanatIncorrectResponses(sessionId);
          if (response.data.message) {
            this.$emit('done', response.data.reset_identifier);
            this.closeResetSession();
            this.successMessage(response.data.message);
          }
        } catch (error) {
          this.closeResetSession();
          this.errorMessage(error.response.data.message);
        } finally {
          this.isLoading = false;
        }
      }
    },
    setup() {
      const errorm = () => {
        ElMessage.error('Une erreur est survenue !')
      }
  
      const successMessage = (message) => {
        ElMessage.success(message)
      }

      const errorMessage = (message) => {
        ElMessage.error(message)
      }
  
      return { errorm, successMessage, errorMessage }
    }
  };
  </script>
  