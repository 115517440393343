<template>
  <div>
    <div v-if="createSessionDialogVisible">
      <CreateSessionModal :createSessionDialogVisible="createSessionDialogVisible"
        :closeCreateSessionModal="closeCreateSessionModal" />
    </div>
    <div v-if="createSessionFreeDialogVisible">
      <CreateFreeSessionModal :createSessionFreeDialogVisible="createSessionFreeDialogVisible"
        :closeCreateFreeSessionModal="closeCreateFreeSessionModal" />
    </div>
    <div v-if="deleteSessionDialogVisible">
      <DeleteSessionModal :deleteSessionDialogVisible="deleteSessionDialogVisible"
        :closeDeleteSessionModal="closeDeleteSessionModal" :selectedSession="selectedSession"
        :closeDeleteSessionModalWithdelete="closeDeleteSessionModalWithdelete" />
    </div>
    <div v-if="renameSessionDialogVisible">
      <RenameSessionModal :renameSessionDialogVisible="renameSessionDialogVisible"
        :closeRenameSessionModal="closeRenameSessionModal" :selectedSession="selectedSession"
        @closeRenameModalWihRename="closeRenameSessionModalWithrename" />
    </div>

    <div v-if="sessionIsSelected">
      <ResetSessionModal :selectedSessionData="selectedSessionData" :type="resetSessionType" :closeResetSession="closeResetSession" @done="resetDone" />
    </div>


    <div
      class="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 sm:pt-6 lg:pt-4 xl:pt-6 space-y-4 h-full">
      <div class="h-full w-full old-series-section">
        <div class="h-full w-full">
          <div class="">
            <p class="section-title">Recherche par titre</p>
            <div class="flex w-full justify-between items-center mb-5 mt-3 sm:flex-row flex-col sm:gap-0 gap-4">
              <div class="flex sm:flex-row flex-col sm:w-auto w-full gap-2">
                <div class="relative sm:w-1/2 w-full">
                  <el-input v-model="searchSession" placeholder="Recherche par titre" />
                  <button class="absolute right-0 top-0 my-3 mr-3">
                    <svg class="h-4 w-4 fill-current text-gray-700" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"
                      viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve"
                      width="512px" height="512px">
                      <path
                        d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                    </svg>
                  </button>
                </div>
                <div class="sm:w-2/5 w-full">
                  <el-select @change="handleModuleChange()" v-model="selectedModule" placeholder="Modules"
                    class="w-full">
                    <el-option v-for="item in modules" :key="item.id" :label="item.title" :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </div>

              <!--<button @click="openSessionDialog"
                class="py-2 flex items-center  text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
                <el-icon color="#fff" :size="15">
                  <plus />
                </el-icon>
                <span class="sm:block sm:ml-2 hidden">Nouvelle série </span>
              </button>-->
              <button class="primary-btn flex p-2 gap-2 items-center sm:w-auto w-full justify-center"
                @click="openSessionDialog">
                <AddBorderedIcon />
                <span>Nouvelle série</span>
              </button>
            </div>
          </div>
          <div :class="isLoading ? 'block' : 'hidden'" class="flex items-center justify-center h-full w-full">
            <!--<div class="fulfilling-square-spinner">
              <div class="spinner-inner"></div>
            </div>-->
            <div class="loading">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </div>
          <div v-if="sessions.length == 0 && !isLoading">
            <el-result icon="info" title="Aucune série"
              subTitle="Il y a aucune ancienne série veuillez créer une nouvelle ">
            </el-result>
          </div>
          <div class="mb-3" v-if="sessions.length > 0 && !isLoading">
            <!-- <el-scrollbar height="450px" class="w-full rounded-lg bg-white"> -->
            <p class="section-title mb-4">Mes series</p>
            <div class="flex flex-col gap-4">
              <div v-for="session in filterSessions" :key="session.id"
                class="session flex sm:flex-row flex-col justify-between items-center border rounded-lg px-6 py-2">
                <div class="sm:w-4/12 w-full text-center mb-4">
                  <p class="session-name sm:text-left text-center">{{ session.name }}</p>
                </div>
                <div class="flex flex-col justify-center sm:w-4/12 w-full">
                  <div class="text-center">
                    <p class="answered-questions-total mb-2">{{ session.answered }}/{{ session.total }}</p>
                    <div class="result-bar justify-center">
                      <span class="tag incorrect">{{ formatNumber(session.noteData.false) }}</span>
                      <span class="tag correct">{{ formatNumber(session.noteData.true) }}</span>
                      <span class="tag unanswered">{{ formatNumber(session.noteData.unanswered) }}</span>
                    </div>
                  </div>
                </div>
                <div class="sm:w-4/12 w-full sm:mt-0 mt-5">
                  <div class="flex sm:justify-end justify-center item-center gap-4">
                    <div
                      :class="(Subscribed || (session.isFree === '1') || session.Subscribed_Module > 0) ? 'block' : 'opacity-0 pointer-events-none'"
                      @click="goSession(session.id)" class="transform hover:text-red-400 hover:scale-110">
                      <!--<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="20" height="20"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polygon points="5 3 19 12 5 21 5 3"></polygon>
                    </svg>-->
                      <PlayIcon />
                    </div>
                    <div @click="renameFunction(session.id, session.name)"
                      class="transform hover:text-red-400 hover:scale-110">
                        <!--<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="20" height="20"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                      </svg>-->
                      <EditIcon />
                    </div>
                    <!--<div class="transform hover:text-red-400 hover:scale-110" @click="handleResetSession(session)">
                      <ResetIcon />
                    </div>-->

                    <div class="relative"> 
                      <div @click="toggleResetDropdown(session.id)" class="transform hover:text-red-400 hover:scale-110 cursor-pointer">
                        <ResetIcon />
                      </div>
                      <div v-if="showDropdown === session.id" v-click-away="handleClickAway" 
                          class="absolute right-0 mt-2 border rounded-lg shadow-lg z-10 reset-dropdown-list">
                        <ul>
                          <li @click="handleResetSession(session.id, 'all')" class="px-4 py-2 hover:bg-gray-100 cursor-pointer whitespace-nowrap text-left">
                            Réinitialiser la session
                          </li>
                          <li @click="handleResetSession(session.id, 'incorrect')" class="px-4 py-2 hover:bg-gray-100 cursor-pointer whitespace-nowrap text-left">
                            Réinitialiser les réponses incorrectes
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div @click="deleteFunction(session.id)"
                      class="transform hover:text-red-400 hover:scale-110 text-red-400">
                      <!--<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="20" height="20"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>-->
                      <DeleteIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- </el-scrollbar> -->
          </div>

          <!-- Pagination -->
          <ul class="relative z-0 inline-flex rounded-md -space-x-px w-full justify-center mt-5"
            v-if="pagination.totalPages > 0 && sessions.length > 0 && !isLoading">

            <!-- Previous button (only show when there are more than 1 page) -->
            <li>
              <button
                class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                @click="prevPage()" :disabled="pagination.currentPage <= 1">
                <span class="sr-only">Previous</span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                  aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd" />
                </svg>
              </button>
            </li>

            <!-- Render only the first page if total pages == 1 -->
            <template v-if="pagination.totalPages === 1">
              <li>
                <button
                  class="hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium z-10 bg-red-50 border-red-400 text-red-400">
                  1
                </button>
              </li>
            </template>

            <!-- Render pagination numbers when there's more than one page -->
            <template v-else>
              <!-- Always display the first page -->
              <li>
                <button class="hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                  :class="pagination.currentPage === 1 ? 'z-10 bg-red-50 border-red-400 text-red-400' : 'bg-white border-gray-300 text-gray-700'"
                  @click="handlePageChange(1)">
                  1
                </button>
              </li>

              <!-- Condensed pagination logic for more than 6 pages -->
              <template v-if="pagination.totalPages > 6">
                <li v-if="pagination.currentPage > 3">
                  <span class="mx-2">...</span>
                </li>

                <li v-for="page in getVisiblePages" :key="page" @click="handlePageChange(page)">
                  <button
                    class="hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                    :class="page == pagination.currentPage ? 'z-10 bg-red-50 border-red-400 text-red-400' : 'bg-white border-gray-300 text-gray-700'">
                    {{ page }}
                  </button>
                </li>

                <li v-if="pagination.currentPage < pagination.totalPages - 2">
                  <span class="mx-2">...</span>
                </li>

                <!-- Always display the last page -->
                <li>
                  <button
                    class="hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                    :class="pagination.currentPage === pagination.totalPages ? 'z-10 bg-red-50 border-red-400 text-red-400' : 'bg-white border-gray-300 text-gray-700'"
                    @click="handlePageChange(pagination.totalPages)">
                    {{ pagination.totalPages }}
                  </button>
                </li>
              </template>

              <!-- Default behavior for 6 or fewer pages -->
              <template v-else>
                <li v-for="page in pagination.totalPages" :key="page" @click="handlePageChange(page)">
                  <button
                    class="hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                    :class="page == pagination.currentPage ? 'z-10 bg-red-50 border-red-400 text-red-400' : 'bg-white border-gray-300 text-gray-700'">
                    {{ page }}
                  </button>
                </li>
              </template>
            </template>

            <!-- Next button (only show when there are more than 1 page) -->
            <li>
              <button
                class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                @click="nextPage()" :disabled="pagination.currentPage >= pagination.totalPages">
                <span class="sr-only">Next</span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                  aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd" />
                </svg>
              </button>
            </li>
          </ul>
          <!-- End Pagination -->


        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import { Plus } from '@element-plus/icons';
import CreateSessionModal from './SessionModals/CreateSessionModal.vue'
import CreateFreeSessionModal from './SessionModals/CreateFreeSessionModal.vue'
import DeleteSessionModal from './SessionModals/DeleteSession.vue'
import RenameSessionModal from './SessionModals/RenameSessionModal.vue'
import { ElMessage } from 'element-plus';
import Chart from 'primevue/chart';
import EditIcon from '../icons/EditIcon.vue';
import DeleteIcon from '../icons/DeleteIcon.vue';
import PlayIcon from '../icons/PlayIcon.vue';
import AddBorderedIcon from '../icons/AddBorderedIcon.vue';
import axiosIns from '../../plugins/axios';
import ResetIcon from '../icons/ResetIcon.vue';
import ResetSessionModal from './SessionModals/ResetSessionModal.vue';
export default {
  components: {
    Plus,
    CreateSessionModal,
    CreateFreeSessionModal,
    DeleteSessionModal,
    RenameSessionModal,
    Chart,
    EditIcon,
    PlayIcon,
    DeleteIcon,
    AddBorderedIcon,
    ResetIcon,
    ResetSessionModal
  },

  setup() {
    const state = reactive({
      percentage: 60,
    })
    const errorm = () => {
      ElMessage.error('Une erreur est survenue !')
    }



    return {
      ...toRefs(state),
      errorm
    }
  },
  data() {
    return {
      createSessionDialogVisible: false,
      createSessionFreeDialogVisible: false,
      deleteSessionDialogVisible: false,
      renameSessionDialogVisible: false,
      isLoading: false,
      searchSession: null,
      sessions: [],
      currentRoute: window.location.pathname,
      selectedSession: null,
      pagination: {
        currentPage: 1, // The current page from your API response
        totalPages: null, // The total number of pages from your API response
      },
      modules: [],
      selectedModule: "",
      sessionIsSelected: false,
      selectedSessionData: {},
      showDropdown: null,
      resetSessionType: ''
    }
  },
  props: {
    linkPath: Function,
    dark: Boolean
  },

  methods: {
    formatNumber(value) {
      return Number.isInteger(value) ? value : Number(value.toFixed(2));
    },
    openSessionDialog() {
      if (this.Subscribed) {
        this.createSessionDialogVisible = true;
      } else {
        this.createSessionFreeDialogVisible = true
      }
    },
    closeCreateSessionModal() {
      this.createSessionDialogVisible = false;
    },
    closeCreateFreeSessionModal() {
      this.createSessionFreeDialogVisible = false;
    },
    closeDeleteSessionModal() {
      this.deleteSessionDialogVisible = false;
      this.selectedSession = null;
    },
    closeDeleteSessionModalWithdelete() {
      this.sessions = this.sessions.filter((tab) => tab.id !== this.selectedSession);
      this.deleteSessionDialogVisible = false;
      this.selectedSession = null;
    },



    closeRenameSessionModal() {
      this.renameSessionDialogVisible = false;
      this.selectedSession = null;
    },

    closeRenameSessionModalWithrename(name) {

      this.sessions.forEach(element => {
        if (element.id === this.selectedSession.id) {
          element.name = name;
        }
      });
      this.renameSessionDialogVisible = false;
      this.selectedSession = null;
    },


    goSession(id,reset_identifier=null) {
      if(reset_identifier != null) {
        this.$router.push(`/dashboard/currentSession/?id=${id}&reset_identifier=${reset_identifier}`);  
      } else {
        this.$router.push("/dashboard/currentSession/?id=" + id);
      }
      
    },

    deleteFunction(id) {
      this.deleteSessionDialogVisible = true;
      this.selectedSession = id;
    },

    renameFunction(id, name) {
      this.renameSessionDialogVisible = true;
      this.selectedSession = { id: id, name: name };
    },

    getSessions() {
      this.isLoading = true
      axiosIns.get("/sessionsofuserS/" + this.User.id + "/" + this.$store.getters.get_subscribeNow,
        {
          params: {
            page: this.pagination.currentPage,
            module: this.selectedModule ? this.selectedModule : null
          }
        }
      )
        .then(({ data }) => {
          this.isLoading = false;
          let temps = []
          this.pagination.totalPages = data.pagination.last_page;
          data.message.forEach(element => {

            const item = {
              ...element,
              chartData: {
                labels: ['Correctes', 'Fausses', 'Non répondues'],
                datasets: [
                  {
                    data: [element.noteData.true, element.noteData.false, element.noteData.unanswered],
                    backgroundColor: [
                      "#34D399",
                      "#F87171",
                      "#D1D5DB"
                    ],
                    hoverBackgroundColor: [
                      "#34D399",
                      "#F87171",
                      "#D1D5DB"
                    ]
                  }
                ]
              }
            }
            temps.push(item);
          });

          this.sessions = temps;
        })
        .catch(() => {
          this.errorm();
          this.isLoading = false;
        });
    },
    handlePageChange(page) {
      this.pagination.currentPage = page;
      // Fetch new data based on the new page number
      this.getSessions();
    },
    handleModuleChange() {
      this.pagination.currentPage = 1;
      this.getSessions();
    },
    prevPage() {
      if (this.pagination.currentPage > 1) {
        this.pagination.currentPage--;
        this.getSessions();
      }
    },
    nextPage() {
      if (this.pagination.currentPage < this.pagination.totalPages) {
        this.pagination.currentPage++;
        this.getSessions();
      }
    },
    getModules() {
      if (this.SubscribeNow != null) {
        axiosIns.get("/moduleofyear/" + this.SubscribeNow).then((response) => {
          this.modules = response.data.modules;
        })
      }
    },
    closeResetSession() {
      this.sessionIsSelected = false;
    },
    handleResetSession(sessionId, type) {
      this.resetSessionType = type
      this.selectedSessionData = {
        id: sessionId
      }
      this.sessionIsSelected = true;
    },

    toggleResetDropdown(sessionId) {
      this.showDropdown = this.showDropdown === sessionId ? null : sessionId;
    },

    handleClickAway() {
      this.showDropdown = null;
    },
    resetDone(reset_identifier) {
      if(this.resetSessionType == 'incorrect') {
        this.goSession(this.selectedSessionData.id,reset_identifier);
      } else if(this.resetSessionType == 'all') {
        this.goSession(this.selectedSessionData.id);
      }
    }
  },

  mounted() {

    this.linkPath(this.currentRoute);
    this.getSessions();
    this.getModules();
  },

  computed: {
    getVisiblePages() {
      const totalPages = this.pagination.totalPages;
      const currentPage = this.pagination.currentPage;
      let pages = [];

      // Show pages around the current page
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pages.push(i);
      }

      return pages;
    },
    isToken() {
      return this.$store.getters.get_token;
    },
    User() {
      return this.$store.getters.get_user;
    },
    SubscribeNow() {
      return this.$store.getters.get_subscribeNow
    },
    Subscriptions() {
      return this.$store.getters.get_subscriptions
    },
    Subscribed() {
      return this.$store.getters.get_subscriptions.includes(String(this.$store.getters.get_subscribeNow))
    },
    filterSessions() {
      if (this.searchSession) {
        return this.sessions.filter((item) => {
          return item.name.toLowerCase().includes(this.searchSession.toLowerCase())
        })
      } else {
        return this.sessions
      }
    }
  },

}
</script>


<style>
.fulfilling-square-spinner,
.fulfilling-square-spinner * {
  box-sizing: border-box;
}

.fulfilling-square-spinner {
  height: 100px;
  width: 100px;
  position: relative;
  border: 4px solid #f87171;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
</style>