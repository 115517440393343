<template>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="currentColor">
            <path d="M2634 4971 c-60 -37 -64 -50 -64 -218 0 -139 -2 -153 -17 -153 -10 0
                        -79 -5 -153 -10 -322 -25 -587 -97 -862 -236 -665 -338 -1105 -972 -1193
                        -1722 -81 -687 153 -1361 640 -1847 342 -342 764 -556 1246 -632 153 -24 507
                        -24 658 0 963 155 1704 884 1872 1842 26 153 36 430 20 586 -36 348 -155 695
                        -336 980 -87 136 -134 172 -227 172 -115 0 -198 -85 -198 -203 0 -47 6 -66 36
                        -116 145 -235 183 -312 238 -477 163 -487 104 -1045 -157 -1489 -86 -147 -170
                        -253 -301 -383 -224 -222 -488 -377 -792 -464 -284 -82 -647 -84 -950 -5 -660
                        171 -1181 721 -1319 1392 -129 621 66 1244 526 1687 200 192 417 326 672 414
                        167 58 404 101 552 101 l45 0 0 -150 c0 -168 8 -198 60 -225 16 -8 48 -15 71
                        -15 38 0 84 26 437 247 280 176 400 256 413 278 23 36 24 86 4 128 -17 35 -4
                        26 -493 334 -351 220 -362 224 -428 184z" />
        </g>
    </svg>
</template>