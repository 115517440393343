<template>
  <div class="">
    <el-dialog v-model="selectExamModal" title="Choisir un examen" :width="isMobile ? '90%' : '60%'" class="w-full"
      center :before-close="closeModal" destroy-on-close>
      <div class=" flex flex-col items-center text-center text-sm px-3">
        <div class="flex sm:flex-row flex-col justify-center items-start w-full">
          <div class="w-full">
            <div class="mb-2">
              <p class="font-semibold text-sm text-gray-800 text-left ml-2">Choisir un type d'épreuve</p>
            </div>
            <el-select @change="handleModuleChange" v-model="selectedType" placeholder="Veuillez sélectionner un type d'épreuve"
              class="w-full">
              <el-option v-for="item in examsTypes" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="flex sm:flex-row flex-col justify-center items-start w-full mt-4">
          <el-table ref="singleTable" :data="exams" style="width: 100%" height="200" highlight-current-row
            @current-change="handleExamChange">

            <el-table-column label="Examens">
              <template #default="scope">
                <div class="p-2 d-flex justify-space-between full">
                  <span>
                    {{ scope.row.name }}
                  </span>
                  <span class="font-bold float-right ml-5">
                    {{ scope.row.number_questions }} Question(s)
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>


        </div>

      </div>


      <template #footer>
        <span class="dialog-footer">
          <button @click="closeModal"
            class="py-2 px-5 mx-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
            Annuler
          </button>
          <button @click="createExam"
            class="py-2 px-4 mx-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
            Confirmer
          </button>

        </span>
      </template>
    </el-dialog>
  </div>


</template>

<script>

import axiosIns from '../../../../../plugins/axios';
export default {
  data() {
    return {

      selectionm: null,

      selectedExam: null,

      // exams types
      examsTypesLoading: false,
      examsTypes: [],
      selectedType: null,

      // exams
      examsLoading: false,
      exams: {
        data: []
      },
      // pagniation
      examsFilter: {
        page: 1
      },
      isMobile: false,
      pagination: {
        currentPage: 1,
        totalPages: null
      },
    }

  },

  props: {
    selectExamModal: Boolean,
    closeSelectExamModal: Function
  },

  mounted() {
    // TODO security
    //if (this.$store.getters.get_subscribeNow != null) {
    this.getExamsTypes();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    this.setupObserver();
    //}
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 576;
    },
    closeModal() {
      this.closeSelectExamModal();
    },

    getExamsTypes() {
      this.examsTypesLoading = true;
      axiosIns.get("/residanat/type_exams").then(({ data }) => {
        this.examsTypesLoading = false;
        this.examsTypes = data;
      }).catch(() => {
        this.examsTypesLoading = false;
      });
    },
    loadExams() {
      if(this.examsLoading) return;
      this.examsLoading = true;
      axiosIns.get("/residanat/exams/type/" + this.selectedType +"?page="+this.pagination.currentPage)
        .then(({ data }) => {
          if (this.pagination.currentPage === 1) {
            this.exams = data.data;
          } else {
            this.exams.push(...data.data);
          }
          
          this.pagination.totalPages = data.last_page;
          
        })
        .catch(() => {
          
        }).finally(() => {
          this.examsLoading = false;
        });

      // pour récupérer la liste des exmens par rapport au module sélectionné
      // il ne faut pas oublier la pagination avec 10 examens par page
      // la liste des examens récupérer doit etre stocké dans :  this.examsSelected

    },

    handleModuleChange() {
      this.pagination.currentPage = 1;
      this.loadExams();
    },

    handleExamChange(val) {
      this.selectedExam = { id: val.id, name: val.name };
    },

    createExam() {
      this.isLoading = true;
      const selectedTypeName = this.examsTypes.find((type) => type.id == this.selectedType);
      axiosIns.post("/residanat/sessionexam",
        {
          exam_id: this.selectedExam.id,
          selectedType: selectedTypeName ? selectedTypeName.title : null
        })
        .then(({ data }) => {
          // this.isLoading = false;
          this.$router.push("/dashboard/residanat/currentSession/?id=" + data.session_id);
          this.closeSelectExamModal();
        })
        .catch(() => {
          // this.isLoading = false;
        });
    },

    handleModuleChange() {
      this.pagination.currentPage = 1;
      this.loadExams();
    },

    handleScroll() {
      const examsLists = document.querySelector('.el-table__body-wrapper');
      if (examsLists) {
        const { scrollTop, scrollHeight, clientHeight } = examsLists;
        const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight - 30; // Adjust threshold as needed
      
        if (isScrolledToBottom && this.pagination.currentPage < this.pagination.totalPages) {
          this.pagination.currentPage += 1;
          this.loadExams();
        }
      }
    },

    setupObserver() {
      this.observer = new MutationObserver(() => {
        const examsLists = document.querySelector('.el-table__body-wrapper');
        if (examsLists) {
          examsLists.addEventListener('scroll', this.handleScroll);
          // Stop observing once the element is found and event attached
          this.observer.disconnect();
        }
      });

      // Observe a higher-level container (e.g., body)
      const container = document.body; // Or replace with a specific higher-level selector
      if (container) {
        this.observer.observe(container, { childList: true, subtree: true });
      } else {
        console.warn('Observer setup failed: container not found.');
      }
    },
  },


  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
    User() {
      return this.$store.getters.get_user;
    },
    Subscribemodules() {
      return this.$store.getters.get_moduleSubscribed;
    },
    SubscribtionModulesList() {
      return this.$store.getters.get_subscriptionsModule;
    },
    sessionName() {
      const selectedModule = this.modules.find((module) => module.value == this.selectionm);
      return `${selectedModule.label} - ${this.selectedExam.name}`;
    }
  },

}
</script>