<template>
  <div class="sm:block hidden">
    <el-dialog v-model="selectExamModal" title="Choisir un examen" width="60%" class=" w-full" center
      :before-close="closeModal" destroy-on-close>

      <div class=" flex flex-col items-center text-center text-sm px-3">

        <div class="flex sm:flex-row flex-col justify-center items-start w-full">
          <div class="w-full">
            <div class="mb-2">
              <p class="font-semibold text-sm text-gray-800 text-left ml-2">Choisir un module</p>
            </div>
            <el-select @change="handleModuleChange" v-model="selectionm" placeholder="Veuillez sélectionner un module"
              class="w-full">
              <el-option v-for="item in modules" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="flex sm:flex-row flex-col justify-center items-start w-full mt-4">
          <el-table ref="singleTable" :data="examsSelected" style="width: 100%" height="200" highlight-current-row
            @current-change="handleExamChange">

            <el-table-column label="Examens">
              <template #default="scope">
                <!--<p class="p-2">{{ scope.row.name }}</p>-->
                <div class="p-2 d-flex justify-space-between full">
                  <span>
                    {{ scope.row.name }}
                  </span>
                  <span class="font-bold float-right ml-5">
                    {{ scope.row.questions_count }} Question(s)
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

      </div>


      <template #footer>
        <span class="dialog-footer">
          <button @click="closeModal"
            class="py-2 px-5 mx-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
            Annuler
          </button>
          <button @click="createExam"
            class="py-2 px-4 mx-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
            Confirmer
          </button>

        </span>
      </template>
    </el-dialog>
  </div>

  <div class="sm:hidden block">
    <el-dialog v-model="selectExamModal" title="Choisir un examen" width="85%" class=" w-full" center
      :before-close="closeModal" destroy-on-close>

      <div class=" flex flex-col items-center text-center text-sm px-3">

        <div class="flex sm:flex-row flex-col justify-center items-start w-full">
          <div class="w-full">
            <div class="mb-2">
              <p class="font-semibold text-sm text-gray-800 text-left ml-2">Choisir un module</p>
            </div>
            <el-select @change="handleModuleChange" v-model="selectionm" placeholder="Veuillez sélectionner un module"
              class="w-full">
              <el-option v-for="item in modules" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="flex sm:flex-row flex-col justify-center items-start w-full mt-4">
          <el-table ref="singleTable" :data="examsSelected" style="width: 100%" height="200" highlight-current-row
            @current-change="handleExamChange">

            <el-table-column label="Examens">
              <template #default="scope">
                <p class="p-2">{{ scope.row.name }}</p>
              </template>
            </el-table-column>
          </el-table>
        </div>

      </div>


      <template #footer>
        <span class="dialog-footer">
          <button @click="closeModal"
            class="py-2 px-5 mx-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
            Annuler
          </button>
          <button @click="createExam"
            class="py-2 px-4 mx-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
            Confirmer
          </button>

        </span>
      </template>
    </el-dialog>
  </div>

</template>

<script>
import axiosIns from '../../../plugins/axios';

export default {
  data() {
    return {

      modules: [],
      selectionm: null,

      selectedExam: null,
      // la liste des examens
      examsSelected: [],
      pagination: {
        currentPage: 1,
        totalPages: null
      },
      isLoading: false
    }

  },

  props: {
    selectExamModal: Boolean,
    closeSelectExamModal: Function
  },

  mounted() {
    if (this.$store.getters.get_subscribeNow != null) {
      if (this.Subscribemodules) {
        axiosIns
          .get("/moduleofyear/" + this.$store.getters.get_subscribeNow)
          .then(({ data }) => {
            let empty = []
            data.modules.forEach(element => {
              if (this.SubscribtionModulesList.includes(element.id)) {
                const item = {
                  label: element.title,
                  value: element.id
                }

                empty.push(item);
              }
            });
            this.modules = empty;
          })
          .catch(() => {
            //this.isLoading = false;
          });
      } else {
        axiosIns
          .get("/moduleofyear/" + this.$store.getters.get_subscribeNow)
          .then(({ data }) => {
            let empty = []
            data.modules.forEach(element => {
              const item = {
                label: element.title,
                value: element.id
              }

              empty.push(item);
            });
            this.modules = empty;
          })
          .catch(() => {
            //this.isLoading = false;
          });
      }
    }


    this.setupObserver();


  },

  methods: {
    closeModal() {
      this.closeSelectExamModal();
    },

    handleModuleChange() {
      this.pagination.currentPage = 1;
      this.loadExams();
    },

    loadExams() {
      //if(this.pagination.totalPages && this.pagination.currentPage >= this.pagination.totalPages) return;
      if (this.isLoading) return;
      this.isLoading = true;
      axiosIns
        .get(`/examensofmodule/${this.selectionm}?page=${this.pagination.currentPage}`)
        .then(({ data }) => {
          
          if (this.pagination.currentPage === 1) {
            this.examsSelected = data.data;
          } else {
            this.examsSelected.push(...data.data);
          }
          
          this.pagination.totalPages = data.meta.last_page;
        })
        .catch(() => {

        })
        .finally(() => {
          this.isLoading = false;
        });

      // pour récupérer la liste des exmens par rapport au module sélectionné
      // il ne faut pas oublier la pagination avec 10 examens par page
      // la liste des examens récupérer doit etre stocké dans :  this.examsSelected


    },

    handleScroll() {
      const examsLists = document.querySelector('.el-table__body-wrapper');
      if (examsLists) {
        const { scrollTop, scrollHeight, clientHeight } = examsLists;
        const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight - 30; // Adjust threshold as needed
        console.log(isScrolledToBottom, this.pagination.currentPage, this.pagination.totalPages)
        if (isScrolledToBottom && this.pagination.currentPage < this.pagination.totalPages) {
          this.pagination.currentPage += 1;
          this.loadExams();
        }
      }

    },


    setupObserver() {
      this.observer = new MutationObserver(() => {
        const examsLists = document.querySelector('.el-table__body-wrapper');
        if (examsLists) {
          examsLists.addEventListener('scroll', this.handleScroll);
          // Stop observing once the element is found and event attached
          this.observer.disconnect();
        }
      });

      // Observe a higher-level container (e.g., body)
      const container = document.body; // Or replace with a specific higher-level selector
      if (container) {
        this.observer.observe(container, { childList: true, subtree: true });
      } else {
        console.warn('Observer setup failed: container not found.');
      }
    },




    handleExamChange(val) {
      this.selectedExam = { id: val.id, name: val.name };
    },

    createExam() {
      this.isLoading = true;
      axiosIns.post("/sessionexam",
        {
          id: this.selectedExam.id,
          user_ID: this.User.id,
          name: this.sessionName
        }
      )
        .then(({ data }) => {
          // this.isLoading = false;
          this.$router.push("/dashboard/currentSession/?id=" + data.result);
          this.closeSelectExamModal();
        })
        .catch(() => {
          // this.isLoading = false;
        });
    }

  },

  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
    User() {
      return this.$store.getters.get_user;
    },
    Subscribemodules() {
      return this.$store.getters.get_moduleSubscribed;
    },
    SubscribtionModulesList() {
      return this.$store.getters.get_subscriptionsModule;
    },
    sessionName() {
      const selectedModule = this.modules.find((module) => module.value == this.selectionm);

      return `${selectedModule.label} - ${this.selectedExam.name}`;
    }
  },

}
</script>

<style></style>