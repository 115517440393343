import axios from '@/plugins/axios';

const SessionRequests = {
    async resetSession(sessionId) {
        try {
            const response = await axios.post(`session/reset/${sessionId}`);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async resetIncorrectResponses(sessionId) {
        try {
            const response = await axios.post(`session/reset-incorrect/${sessionId}`);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async resetAnnalesResidanatSession(sessionId) {
        try {
            const response = await axios.post(`residanat/session/reset/${sessionId}`);
            return response;
        } catch (error) {
            throw error;
        }
    },

    async resetAnnalesResidanatIncorrectResponses(sessionId) {
        try {
            const response = await axios.post(`residanat/session/reset-incorrect/${sessionId}`);
            return response;
        } catch (error) {
            throw error;
        }
    }
    
}

export default SessionRequests;